import React from 'react';
import Banner from '../components/Banner/Banner'
import Coinstable from '../components/CoinsTable'
const Homepage = () => {
  return (<>
      <Banner />
      <Coinstable />
  </>);
};

export default Homepage;
